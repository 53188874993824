import { createSlice } from '@reduxjs/toolkit';
import { cudOption, deleteOption, getOptions, getSearchedOptions, uploadFileInOption, } from './optionThunk';
const initialState = {
    allOptions: [],
    option: {},
    optionToUpdate: {},
    allOptionsStatus: 'idle',
    optionsStatus: 'idle',
    deleteOptionsStatus: 'idle',
    uploadFile: '',
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'cabinet',
    initialState,
    reducers: {
        setOptionToUpdate: (state, action) => {
            state.optionToUpdate = action.payload;
        },
        removeOptionToUpdate: (state) => {
            state.optionToUpdate = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOptions.pending, (state) => {
            state.allOptionsStatus = 'loading';
        })
            .addCase(getOptions.fulfilled, (state, { payload }) => {
            state.allOptionsStatus = 'succeeded';
            state.allOptions = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getOptions.rejected, (state, { payload }) => {
            state.error = true;
            state.allOptionsStatus = 'failed';
        })
            .addCase(uploadFileInOption.fulfilled, (state, { payload }) => {
            state.uploadFile = payload.data;
        })
            .addCase(uploadFileInOption.rejected, (state, { payload }) => {
            state.error = true;
        })
            .addCase(getSearchedOptions.pending, (state) => {
            state.allOptionsStatus = 'loading';
        })
            .addCase(getSearchedOptions.fulfilled, (state, { payload }) => {
            state.allOptionsStatus = 'succeeded';
            state.allOptions = payload.data.docs;
            state.total = payload.data.totalDocs;
        })
            .addCase(getSearchedOptions.rejected, (state, { payload }) => {
            state.error = true;
            state.allOptionsStatus = 'failed';
        })
            .addCase(cudOption.pending, (state, actions) => {
            state.optionsStatus = 'loading';
        })
            .addCase(cudOption.fulfilled, (state, { payload }) => {
            state.optionsStatus = 'succeeded';
        })
            .addCase(cudOption.rejected, (state, { payload }) => {
            state.optionsStatus = 'failed';
        })
            .addCase(deleteOption.pending, (state, actions) => {
            state.deleteOptionsStatus = 'loading';
        })
            .addCase(deleteOption.fulfilled, (state, { payload }) => {
            state.deleteOptionsStatus = 'succeeded';
        })
            .addCase(deleteOption.rejected, (state, { payload }) => {
            state.error = true;
            state.deleteOptionsStatus = 'failed';
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { setOptionToUpdate, removeOptionToUpdate } = slice.actions;
